.container {
    display: flex;
    padding-top: 1px;
    padding-left: 1px;
}

.grid {
    display: grid;
    font-size: 16px;
    margin: auto;

    >img {
        padding-right: 1px;
        padding-bottom: 1px;
    }
}
