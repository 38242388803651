.grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.container {
    display: flex;
    justify-content: center;
    margin-bottom: 32px;
}

.item {
    border-color: lightgray;
    border-width: 0.5 px;
    border-style: solid;

    > img {
        display: block;
        image-rendering: pixelated;
    }

    .selected {
        background-color: lightgreen;
    }

    &:hover {
        background-color: lightgray;
    }
}
