.tile {
    min-width: 34px;
    min-height: 34px;

    > img {
        display: block;
        image-rendering: pixelated;
    }
}

.hatch {
    background-color: lightgray;
    border: 1px gray;
    border-style: solid;
}

.casing {
    background-color: #F8F8F8;
    border: 1px #EEEEEE;
    border-style: solid;
}


