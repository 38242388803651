.info {
    font-size: 14px;
    background-color: white;
    border-style: solid;
    border-color: black;
    font-family: monospace;
    font-weight: 700;
}

.container {
    position: absolute;
    margin-left: 40px;
    margin-top: 8px;
}